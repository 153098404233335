import { NavLink, useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";

export default function BannerForm({
  bannerTitle = "",
  bannerParagraph = "",
  primaryLinkTitle = "",
  primaryLink = "",
  secondaryLinkTitle = "",
  secondaryLink = "",
  bgImg = "",
  bannerRightImg = "",
  bannerRightImgAlt = "",
  primaryLinkInternal = false,
  secondaryLinkInternl = false,
  bgColor = "",
  bgRepeat = "",
  pageUrl = "",
  service_title = "",
}) {
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneno, setPhoneNo] = useState("");
  const [description, setDescription] = useState("");

  const [errors, setErrors] = useState({});
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const navigate = useNavigate();

  const enquirypostApi =
    "https://backend.sourcedesk.io/api/v1/service-form-submit-action";

  const validate = () => {
    let tempErrors = {};
    if (!fullname) tempErrors.fullname = "Name is required";
    if (!email) tempErrors.email = "Email Id is required";
    if (!emailRegex.test(email))
      tempErrors.email = "Please enter valid Email Id";
    if (!phoneno) tempErrors.phoneno = "Phone No. is required";
    if (phoneno.length != 10)
      tempErrors.phoneno = "Please enter valid Phone No.";
    if (isNaN(phoneno))
      tempErrors.phoneno = "Phone No. should have only numeric value";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  function handleSumbit() {
    if (validate()) {
      const userdata = {
        fullname: fullname,
        email: email,
        phoneno: phoneno,
        description: description,
        pageUrl: pageUrl,
        service_title: service_title,
      };
      

      axios({
        method: "post",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: enquirypostApi,
        data: userdata,
      })
        .then(({ data }) => {
          

          if (data.result == 1) {

            window.location.href = "https://www.sourcedesk.io/thank-you?thank-you=4";
            
            // Swal.fire({
            //   icon: "success",
            //   text: "Done! Data successfully submitted.",
            // });

            //navigate("/thank-you?thank-you=4");
           
          } else {
            Swal.fire({
              icon: "error",
              text: "Something went wrong!",
            });
          }
        })
        .catch(({ response }) => {
          
          Swal.fire({
            icon: "error",
            text: "Something went wrong!",
          });
        });
    } else {
      console.log("form validation failed!");
    }
  }

  return (
    <section className="full_width Home_banner inner_banner apply_talent_banner react_banner">
      <div
        className="Home_banner_sub hide-bg-early"
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundColor: bgColor,
          backgroundRepeat: bgRepeat,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-6 Hom_bnr_left inner_bnr_left aiml_form_sec text-center text-md-start">
              <div className="banner_text">
                <h1>{bannerTitle}</h1>
                <form>
                  <input
                    type="hidden"
                    name="_token"
                    value="z3k5OJXgRd29FLwQqvqPqdHEdFrsMHAf8YtMaCH3"
                  />{" "}
                  <div className="aiml_form d-block d-md-none my-4 my-md-0">
                    <p className="title">
                      <span>Request a</span>Free Quote Today!
                    </p>
                    <div className="row">
                      <div className="col-12">
                        <input
                          type="text"
                          placeholder="Name"
                          name="fullname"
                          id="banner-form__name"
                          autocomplete="off"
                          value={fullname}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                        {errors.fullname && (
                          <p style={{ color: "red" }}>{errors.fullname}</p>
                        )}
                      </div>

                      <div className="col-12">
                        <input
                          type="email"
                          placeholder="Email Id"
                          name="email"
                          id="banner-form__email"
                          autocomplete="off"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />

                        {errors.email && (
                          <p style={{ color: "red" }}>{errors.email}</p>
                        )}
                      </div>

                      <div className="col-12">
                        <input
                          type="tel"
                          placeholder="Phone No."
                          name="moblie"
                          id="banner-form__mobile"
                          autocomplete="off"
                          value={phoneno}
                          onChange={(e) => setPhoneNo(e.target.value)}
                        />

                        {errors.phoneno && (
                          <p style={{ color: "red" }}>{errors.phoneno}</p>
                        )}
                      </div>

                      <div className="col-12">
                        <textarea
                          placeholder="Description"
                          name="description"
                          id="banner-form__description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        ></textarea>

                        {errors.description && (
                          <p style={{ color: "red" }}>{errors.description}</p>
                        )}

                        <input type="hidden" name="site_url" value={pageUrl} />
                        <input
                          type="hidden"
                          name="service_title"
                          value={service_title}
                        />
                      </div>

                      <div className="col-12">
                        <input
                          type="submit"
                          value="Submit"
                          onClick={(e) => {
                            e.preventDefault();
                            handleSumbit();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </form>
                {bannerParagraph === "" ? false : <p>{bannerParagraph}</p>}
              </div>
              <div className="cmn_btn_grp justify-content-center justify-content-md-start">
                {primaryLink === "" || primaryLinkTitle === "" ? (
                  false
                ) : primaryLinkInternal ? (
                  <a href={primaryLink} className="cmn_btn_fill">
                    {primaryLinkTitle}
                  </a>
                ) : (
                  <NavLink to={primaryLink} className="cmn_btn_fill">
                    {primaryLinkTitle}
                  </NavLink>
                )}
                {secondaryLink === "" || secondaryLinkTitle === "" ? (
                  false
                ) : secondaryLinkInternl ? (
                  <a href={secondaryLink} className="cmn_btn_stroke">
                    {secondaryLinkTitle}
                  </a>
                ) : (
                  <NavLink to={secondaryLink} className="cmn_btn_stroke">
                    {secondaryLinkTitle}
                  </NavLink>
                )}
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 inner_bnr_right d-none d-lg-block">
              <img src={`${bannerRightImg}`} alt={bannerRightImgAlt} />
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-5 mt-md-0 d-none d-md-block">
              <form>
                <input
                  type="hidden"
                  name="_token"
                  value="z3k5OJXgRd29FLwQqvqPqdHEdFrsMHAf8YtMaCH3"
                />{" "}
                <div className="aiml_form">
                  <p className="title">
                    <span>Request a</span>Free Quote Today!
                  </p>
                  <div className="row">
                    <div className="col-12">
                      <input
                        type="text"
                        placeholder="Name"
                        name="fullname"
                        id="banner-form__name"
                        autocomplete="off"
                        value={fullname}
                        onChange={(e) => setFullName(e.target.value)}
                      />
                      {errors.fullname && (
                        <p style={{ color: "red" }}>{errors.fullname}</p>
                      )}
                    </div>

                    <div className="col-12">
                      <input
                        type="email"
                        placeholder="Email Id"
                        name="email"
                        id="banner-form__email"
                        autocomplete="off"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />

                      {errors.email && (
                        <p style={{ color: "red" }}>{errors.email}</p>
                      )}
                    </div>

                    <div className="col-12">
                      <input
                        type="tel"
                        placeholder="Phone No."
                        name="moblie"
                        id="banner-form__mobile"
                        autocomplete="off"
                        value={phoneno}
                        onChange={(e) => setPhoneNo(e.target.value)}
                      />

                      {errors.phoneno && (
                        <p style={{ color: "red" }}>{errors.phoneno}</p>
                      )}
                    </div>

                    <div className="col-12">
                      <textarea
                        placeholder="Description"
                        name="description"
                        id="banner-form__description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>

                      {errors.description && (
                        <p style={{ color: "red" }}>{errors.description}</p>
                      )}

                      <input type="hidden" name="site_url" value={pageUrl} />
                      <input
                        type="hidden"
                        name="service_title"
                        value={service_title}
                      />
                    </div>

                    <div className="col-12">
                      <input
                        type="submit"
                        value="Submit"
                        onClick={(e) => {
                          e.preventDefault();
                          handleSumbit();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
