import React from "react";
import TrustedSlider from "../Components/TrustedSlider";
import UspContent from "../Components/UspContent";
import WhySdg from "../Components/WhySdg";
import Potential from "../Components/Potential";
import TalentVTab from "../Components/TalentVTab";
import TalentParameter from "../Components/TalentParameter";
import PrideHire from "../Components/PrideHire";
import TopTalent from "../Components/TopTalent";
import SkillTab from "../Components/SkillTab";
import Homepgbrk from "../Components/Homepgbrk";
import HomeBanner from "../Components/HomeBanner";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

function Home() {
  const location = useLocation();
  const baseURL = `${window.location.protocol}//${window.location.host}`; // Dynamically get base URL
  const fullURL = `${baseURL}${location.pathname}`;

  const schemaData= {
      
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Sourcedesk",
    "url": "https://www.sourcedesk.io/",
    "email": "info@sourcedeskglobal.com",
    "logo": "https://www.sourcedesk.io/images/logo.png",
    "address": [
      {
        "@type": "PostalAddress",
        "streetAddress": "Shaila Tower, EP-204, Salt Lake, Sector-V",
        "addressLocality": "Kolkata",
        "addressRegion": "West Bengal",
        "postalCode": "700091",
        "addressCountry": "India"
      },
      {
        "@type": "PostalAddress",
        "streetAddress": "Cra. 42 #3 Sur 81 Torre 1 Piso 15, El Poblado",
        "addressLocality": "Medellín",
        "addressRegion": "Antioquia",
        "postalCode": "",
        "addressCountry": "Colombia"
      },
      {
        "@type": "PostalAddress",
        "streetAddress": "One Liberty Plaza, 165 Broadway, Suite 2301",
        "addressLocality": "New York",
        "addressRegion": "NY",
        "postalCode": "10006",
        "addressCountry": "USA"
      },
      {
        "@type": "PostalAddress",
        "streetAddress": "5399 Eglinton Ave. West Suite 212",
        "addressLocality": "Toronto",
        "addressRegion": "ON",
        "postalCode": "M9C 5K6",
        "addressCountry": "Canada"
      }
    ]
  };

  const breadcrmbSchema= {

  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://www.sourcedesk.io/"
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "About Us",
      "item": "https://www.sourcedesk.io/about-us"
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Our Team",
      "item": "https://www.sourcedesk.io/our-team"
    },
    {
      "@type": "ListItem",
      "position": 4,
      "name": "Blog",
      "item": "https://www.sourcedesk.io/blog"
    },
    {
      "@type": "ListItem",
      "position": 5,
      "name": "Contact Us",
      "item": "https://www.sourcedesk.io/contact-us"
    }
  ]

};

  return (
    <>
      <Helmet>
        <title>
          Hire Pre-Vetted Remote Individuals or Build a Team | Sourcedesk
        </title>
        <meta
          name="description"
          content="Hire pre-vetted remote professionals or build your dream team effortlessly with Sourcedesk. Streamlined hiring, top 10% talent, and cutting-edge AI await."
        />
        {/* CANONICAL & HREFLANG */}
        <link rel="canonical" href={fullURL} />
        <link rel="alternate" hrefLang="x-default" href={fullURL} />
        <link rel="alternate" hrefLang="en-US" href={fullURL} />
        {/* FACEBOOK */}
        <meta property="og:url" content={fullURL} />
        <meta
          property="og:title"
          content="Hire Pre-Vetted Remote Individuals or Build a Team | Sourcedesk"
        />
        <meta
          property="og:description"
          content="Hire pre-vetted remote professionals or build your dream team effortlessly with Sourcedesk. Streamlined hiring, top 10% talent, and cutting-edge AI await."
        />
        {/* TWITTER/X */}
        <meta
          name="twitter:title"
          content="Hire Pre-Vetted Remote Individuals or Build a Team | Sourcedesk"
        />
        <meta
          name="twitter:description"
          content="Hire pre-vetted remote professionals or build your dream team effortlessly with Sourcedesk. Streamlined hiring, top 10% talent, and cutting-edge AI await."
        />

        {/* LocalBusiness Schema */}
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
        {/* Breadcrumb Schema */}
        <script type="application/ld+json">{JSON.stringify(breadcrmbSchema)}</script>
       
      </Helmet>
      <HomeBanner />

      <TrustedSlider defaultTitle="Trusted By" />

      <UspContent />

      <WhySdg />

      <Potential />

      <TalentVTab />

      <SkillTab />

      <TalentParameter />

      <PrideHire />

      <TopTalent />

      <Homepgbrk />
    </>
  );
}

export default Home;
