import React, { useEffect, useState } from "react";
import axios from "axios";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Accordion from "react-bootstrap/Accordion";
import InnerBanner from "../Components/InnerBanner";
import BlogsSliderpopular from "../Components/BlogsSliderpopular";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

function UncontrolledExample() {
  const get_pricing_data = "https://backend.sourcedesk.io/api/v1/get-pricing-list";
  const [pricingList, setpricingdata] = useState([]);

  const getpricingData = () => {
    axios
      .get(get_pricing_data)
      .then((res) => {
        setpricingdata(res.data);

        
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getpricingData();
  }, []);

  if (pricingList.length > 0) {
    return (
      <>
        <div className="d-none d-md-block">
          <Tabs defaultActiveKey="0" id="pricing-tabs">
            {pricingList.map((el, i) => (
              <Tab eventKey={el.key_id} title={el.category_title}>
                <div className="row ">
                  {el.pricing_data.map((res, j) => (
                    <div className="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4">
                      <div className="prcng_sec_item">
                        <div className="prcng_sec_item_icon">
                          <img src={res.pricing_icon} alt={res.pricing_title} />
                        </div>
                        <div className="prcng_sec_item_text">
                          <h3>{res.pricing_title}</h3>
                          <h4>{res.pricing_short_description}</h4>
                          <h2
                            dangerouslySetInnerHTML={{
                              __html: res.pricing_amount,
                            }}
                          ></h2>{" "}
                          <span>{res.pricing_rate_unit}</span>{" "}
                          <span>{res.pricing_full_description}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Tab>
            ))}
          </Tabs>
        </div>
        <div className="d-block d-md-none">
          <Accordion defaultActiveKey="">
            {pricingList.map((el, i) => (
              <Accordion.Item eventKey={el.key_id}>
                <Accordion.Header>{el.category_title}</Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    {el.pricing_data.map((res, j) => (
                      <div className="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4">
                        <div className="prcng_sec_item">
                          <div className="prcng_sec_item_icon">
                            <img
                              src={res.pricing_icon}
                              alt={res.pricing_title}
                            />
                          </div>
                          <div className="prcng_sec_item_text">
                            <h3>{res.pricing_title}</h3>
                            <h4>{res.pricing_short_description}</h4>
                            <h2
                              dangerouslySetInnerHTML={{
                                __html: res.pricing_amount,
                              }}
                            ></h2>{" "}
                            <span>{res.pricing_rate_unit}</span>{" "}
                            <span>{res.pricing_full_description}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </>
    );
  }
}
export default function Pricing() {
  return (
    <>
      <Helmet>
        <title>Sourcedesk's Remote Staffing Solution | Look At Or Rates</title>
        <meta
          name="description"
          content="Explore Sourcedesk's remote staffing solution. Discover pricing details now for an efficient and cost-effective hiring strategy. Elevate your workforce management."
        />
        <meta
          name="title"
          content="Sourcedesk's Remote Staffing Solution | Look At Or Rates"
        />
        {/* <!-- Canonical & hrefLang --> */}
        <link rel="canonical" href="https://www.sourcedesk.io/pricing" />
        <link
          rel="alternate"
          hrefLang="x-default"
          href="https://www.sourcedesk.io/pricing"
        />
        <link
          rel="alternate"
          hrefLang="en-US"
          href="https://www.sourcedesk.io/pricing"
        />

        {/* <!-- Twitter Card meta tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@sourcedeskgl" />
        <meta
          name="twitter:title"
          content="Sourcedesk's Remote Staffing Solution | Look At Or Rates"
        />
        <meta
          name="twitter:description"
          content="Explore Sourcedesk's remote staffing solution. Discover pricing details now for an efficient and cost-effective hiring strategy. Elevate your workforce management."
        />
        <meta
          name="twitter:image"
          content="https://www.sourcedesk.io/public/images/Twitter-1200x600.jpg"
        />
        {/* <!-- Facebook Open Graph tags --> */}
        <meta property="og:url" content="https://www.sourcedesk.io/pricing" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Sourcedesk's Remote Staffing Solution | Look At Or Rates"
        />
        <meta
          property="og:description"
          content="Explore Sourcedesk's remote staffing solution. Discover pricing details now for an efficient and cost-effective hiring strategy. Elevate your workforce management."
        />
        <meta
          property="og:image"
          content="https://www.sourcedesk.io/public/images/Fcebook-2400x1260.jpg"
        />
        {/* <!-- Additional Open Graph tags for better customization --> */}
        <meta property="og:image:width" content="2400" />
        <meta property="og:image:height" content="1260" />
        <meta property="og:image:alt" content="SourceDesk" />
        <meta property="og:locale" content="en_US" />

        {/* <!-- Head  --> */}
        {/* <!-- Google Tag Manager --> */}
        {/* <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NVLH3JKB');</script> */}
        {/* <!-- End Google Tag Manager --> */}
        {/* <!-- Required meta tags --> */}
        {/* <meta charset="utf-8"  />
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
<meta name="google-site-verification" content="H6B0Yhz76GdiqjxekYyzCSTY94kBUEyyCFzGURiRZVw " />
<meta name="robots" content="index,follow" /> */}

        {/* <!-- Title Bar Icon --> */}
        <link
          rel="icon"
          href="https://www.sourcedesk.io/public/images/favicon.png"
          type="image/x-icon"
        />

        {/* <!-- Head End --> */}
        <script type="application/ld+json">
          {`{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "@id": "https://www.sourcedesk.io/#breadcrumb",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://www.sourcedesk.io/"
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Why Sourcedesk",
      "item": "javascript:void(0)"
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Pricing",
      "item": "https://www.sourcedesk.io/pricing"
    }
  ]
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "http://schema.org",
  "@type": "Review",
  "itemReviewed": {
    "@type": "Product",
    "name": "Our Pricing",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.5",
      "bestRating": "5",
      "worstRating": "1",
      "ratingCount": "100"
    }
  },
  "author": {
    "@type": "Person",
    "name": "SourceDesk"
  },
  "reviewRating": {
    "@type": "Rating",
    "ratingValue": "4.5",
    "bestRating": "5",
    "worstRating": "1"
  },
  "reviewBody": "Review Body"
}`}
        </script>
      </Helmet>

      <InnerBanner
        InnBanner="images/pricing_banner.jpg"
        bgColor="#fafbfd"
        bgRepeat="no-repeat"
        BnrRightImg="images/pricing_bnr_grphcs.png"
        TitleInn={[
          <span>
            Hiring Solutions
            <br /> Tailored for Every Budget
          </span>,
        ]}
        ParaInn="Customized Solutions to Meet Your Talent Acquisition Needs"
        priCTALink={"#our-pricing"}
        priCTATitle={[
          "Access Our Rate Card for Pricing Details",
          // <img src="images/downloads.png" alt="" />,
        ]}
        secCTALink={""}
        secCTATitle={""}
        priLinkInternal={true}
      />
      <section
        className="full_width ourTeamtab pricing_tab fix_padding"
        id="our-pricing"
      >
        <div className="container">
          <div className="text_controller text_controller_center">
            <h2>
              Flexible Hiring Solutions for Optimal Value –<br />
              <span> Tailored to Your Needs</span>
            </h2>
            <hr />
          </div>
          <UncontrolledExample />
          <div className="cmn_btn_grp cmn_btn_grp_center">
            <NavLink to="/schedule-a-call" className="cmn_btn_fill">
              <h4 style={{ fontSize: "14px", margin: "0" }}>
                Engage with Top Industry Professionals
              </h4>
            </NavLink>
          </div>
        </div>
      </section>
      <section className="full_width vrtul_asstnt_devloper  our_price  fix_padding">
        <div className="container">
          <div className=" text_controller text_controller_center">
            <h2>
              Comprehensive Services <br />
              Included in Our Pricing
            </h2>
            <hr />
          </div>
          <div className="sdg_devlopers_technologies">
            <ul>
              <li>
                <h3>Job Advertising </h3>
              </li>
              <li>
                <h3>Pre-Vetted Candidates </h3>
              </li>
              <li>
                <h3>Résumé Screening and Interview Scheduling </h3>
              </li>
              <li>
                <h3>Salary Negotiations</h3>
              </li>
              <li>
                <h3>Employment/Contractor Agreements and Hiring</h3>
              </li>
              <li>
                <h3>Employee Onboarding</h3>
              </li>
              <li>
                <h3>Payroll Management and Compliance </h3>
              </li>
              <li>
                <h3>Performance Review and Bonus Programs </h3>
              </li>
              <li>
                <h3>IT Support </h3>
              </li>
              <li>
                <h3>Employee Retention Strategies </h3>
              </li>
              <li>
                <h3>Attendance Tracking</h3>
              </li>
              <li>
                <h3>Leave Management</h3>
              </li>
              <li>
                <h3>Succession Planning</h3>
              </li>
              <li>
                <h3>Medical Benefits and Perks</h3>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="full_width  add_ons fix_padding">
        <div className="container">
          <div className=" text_controller text_controller_center">
            <h2>Enhance Your Hiring Experience with Add-Ons</h2>
            <hr />
            <p>
              Choose Sourcedesk for top talent and cost optimization. Recruiting three
              or more professionals significantly reduces hiring costs. Get a
              2.5% to 7% discount with advance payment and maximize efficiency
              with exclusive add-ons.
            </p>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-md-6 col-sm-6">
              <div className="add_ons_item">
                <h2>Team Discount</h2>
                <table>
                  <thead>
                    <tr>
                      <td>Team Size</td>
                      <td>Discount</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>More than 3 members</td>
                      <td>2.5% off</td>
                    </tr>
                    <tr>
                      <td>More than 10 members</td>
                      <td>5% off</td>
                    </tr>
                    <tr>
                      <td>More than 15 members</td>
                      <td>7.5% off</td>
                    </tr>
                    <tr>
                      <td>More than 25 members</td>
                      <td>10% off</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 col-lg-6 col-md-6 col-sm-6">
              <div className="add_ons_item">
                <h2>Advance Payment Discount</h2>
                <table>
                  <thead>
                    <tr>
                      <td>Advance Payment</td>
                      <td>Discount</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Quarterly</td>
                      <td>2.50% off</td>
                    </tr>
                    <tr>
                      <td>Half Yearly</td>
                      <td>5.00% off</td>
                    </tr>
                    <tr>
                      <td>Yearly</td>
                      <td>7.50% off</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="cmn_btn_grp cmn_btn_grp_center">
            <NavLink to="/schedule-a-call" className="cmn_btn_fill">
              Explore Our Top Talent Pool
            </NavLink>
          </div>
        </div>
      </section>
      <section className="full_width  guarntee fix_padding">
        <div className="container">
          <div className=" text_controller text_controller_center">
            <h2>
              14-Day Performance Guarantee <br />
              Your Satisfaction Is Our Priority
            </h2>
            <hr />
          </div>
          <div className="row">
            <div className="col-md-7 guarntee_text">
              <p>
                Our platform offers a 14-day risk-free trial period, allowing
                you to evaluate our offsite resources by collaborating with a
                candidate of your choice and experiencing our service quality
                firsthand, prioritizing your satisfaction.
              </p>
              <p>
                If you are not fully satisfied with your chosen candidate during
                the 14-day trial, enjoy a hassle-free replacement at no extra
                cost. We're committed to ensuring your satisfaction and finding
                the perfect fit for your long-term goals.
              </p>
              <p>
                Enjoy peace of mind with our 14-day performance guarantee,
                showcasing our commitment to excellence. Experience the
                difference of working with our talented resources and find the
                perfect match for your business needs.
              </p>
            </div>
            <div className="col-md-5 guarntee_img">
              <img
                src="images/pricing_grphcs1.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <BlogsSliderpopular heading="Leadership Thoughts" />
      <section className="full_width foot_gap pricing_pgbk fix_padding">
        <div className="container">
          <h2>Take the First Step Towards Success</h2>
          <div className="cmn_btn_grp cmn_btn_grp_center">
            <a
              href="#our-pricing"
              className="cmn_btn_stroke"
              // onClick={(e) => {
              //   e.preventDefault();
              // }}
            >
              Access Our Rate Card for Pricing Details
            </a>
            <NavLink to="/schedule-a-call" className="cmn_btn_fill">
              Engage with Top Industry Professionals
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
}
