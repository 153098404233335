// import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";


export default function ScheduleACalltwo() {
  return <>
  <Helmet>
  <title>Schedule a Call to Explore Exciting Opportunities at Sourcedesk</title>
<meta name="description" content="Seize your career's next chapter! Schedule a call with Sourcedesk to explore compelling opportunities. Elevate your success story with confidence. Connect now!" />

    
<link rel="icon" href="https://www.sourcedesk.io/public/images/favicon.png" type="image/x-icon" /> 

  </Helmet>
    <section class="full_width no_banner_sec schedule_step_one border_top fix_padding">
        <div class="container">
            <div class="text_controller text_controller_center">
                <h1>Proceed to Access <span> Unmatched Expertise </span></h1>
                <hr />
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="step_one_content">
                    <div class="text_controller">
                            <h4>STEP 2 OF 2</h4>
                            <h2><b>Select a</b> <br/><span>Date & Time</span></h2>
                            <p>Book a meeting to  <NavLink to="/schedule-a-call">see your shortlist</NavLink></p>

                            <ul>
                                <li>Our dedicated Account Manager will address your queries and assist throughout the process.</li>
                                <li>Set up an account with us to access detailed profiles of pre-vetted talent.</li>
                                <li>Select the best talent after the interview process.</li>
                                <li>Begin a two-week zero-risk trial to evaluate their potential.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="step_one_form">
                    <div class="row">
                    
                    <div class="zoho-inline-widget col-sm-12 schedule_calender">  
                    <iframe width='100%' height='750px' src='https://sourcedeskio.zohobookings.com/portal-embed#/booking' frameborder='0' allowfullscreen='' > </iframe>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </>
}