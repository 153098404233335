import BannerForm from "../Components/BannerForm";
import TrustedSlider from "../Components/TrustedSlider";
import InnerBanner from "../Components/InnerBanner";
import DevProfiles from "../Components/DevProfiles";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Accordion from "react-bootstrap/Accordion";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import Loader from "../Components/Loader";
import useIsMobile from "../Components/useIsMobile";
import AboveScreenWidth from "../Components/AboveScreenWidth";
import BelowScreenWidth from "../Components/BelowScreenWidth";

export default function CustomSoftwareDevelopment() {
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();
  const get_service_details =
    "https://backend.sourcedesk.io/api/v1/get-service-details";
  const [serviceDetails, setserviceDetails] = useState([]);
  const [serviceCasestudy, setserviceCasestudy] = useState([]);

  const [serviceIncludes, setserviceIncludes] = useState([]);
  const [servicePlatform, setservicePlatform] = useState([]);

  const { id } = useParams();
  const currentUrl = window.location.href;
  const navigate = useNavigate();

  useEffect(() => {
    getServicedetails();
  }, [pathname]);

  const getServicedetails = () => {
    setLoading(true);
    axios
      .get(get_service_details, {
        params: {
          slug: id,
        },
      })
      .then(function (response) {
      
        if (response.data.service_details_array.length > 0) {
          setserviceDetails(response.data.service_details_array);
          setserviceCasestudy(response.data.case_study_array);

          setserviceIncludes(response.data.service_includes_array);
          setservicePlatform(response.data.service_platform_array);
        } else {
          navigate("/");
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  if (serviceDetails.length > 0) {
    if (serviceDetails[0].parent_service_id === 0) {
      const solutionsTabData = serviceIncludes;

      const FAQsData = [
        {
          faq: `How much do ${serviceDetails[0].service_title} services from Sourcedesk cost?`,
          ans: "To learn more about the pricing of our services, fill out the contact form, and we will be in touch to discuss pricing.",
        },
        {
          faq: "What if I am not happy with the services and want to exit the contract?",
          ans: `We provide a two-week free trial so that you can test before using our services. However, if you want to cancel the engagement after signing the contract, give us 30 days' notice.`,
        },
        {
          faq: `How long have you been in business?`,
          ans: `Sourcedesk has been operational for more than 12 years. We have provided software development services to more than 1100 clients.`,
        },
        {
          faq: `Do you assign a project manager to each client?`,
          ans: `We assign a dedicated project manager to keep you updated on your project's progress, ensuring everything is moving forward just the way you want it.`,
        },
      ];


    // const serviceSchema= {
    //   "@context": "https://schema.org",
    //   "@type": "Service",
    //   "name": serviceDetails[0].service_title,
    //   "description": `Simplify your business with Sourcedesk ${serviceDetails[0].service_title} services. Get expert web, mobile, and API solutions at affordable prices.`,
    //   "serviceType": serviceDetails[0].service_title,
    //   "provider": {
    //     "@type": "Organization",
    //     "name": "SourceDesk",
    //     "url": "https://www.sourcedesk.io",
    //     "logo": "https://www.sourcedesk.io/images/logo.png",
    //     "sameAs": "https://in.linkedin.com/company/sourcedesk-global"
    //   },
    //   "offers": {
    //     "@type": "Offer",
    //     "url": currentUrl,
    //     "priceCurrency": "USD",
    //     "price": "Request a Quote",
    //     "eligibleRegion": {
    //       "@type": "Place",
    //       "name": "Global"
    //     }
    //   },
    //   "audience": {
    //     "@type": "Audience",
    //     "audienceType": "Businesses, Enterprises, Startups",
    //     "geographicArea": {
    //       "@type": "Place",
    //       "name": "Global"
    //     }
    //   },
    //   "mainEntityOfPage": currentUrl
    // };


    const serviceSchema= {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": serviceDetails[0].service_title,
      "image": serviceDetails[0].service_banner_img,
      "description": `Simplify your business with Sourcedesk ${serviceDetails[0].service_title} services. Get expert web, mobile, and API solutions at affordable prices.`,
      "brand": {
        "@type": "Organization",
        "name": "Sourcedesk Global",
        "url": "https://www.sourcedesk.io"
      },
      "sku": "SDG-SERV-"+serviceDetails[0].service_id,
      "url": currentUrl,
      "offers": {
        "@type": "Offer",
        "url": currentUrl,
        "priceCurrency": "USD",
        "price": "0.00",
        "priceValidUntil": "2025-12-31",
        "availability": "https://schema.org/InStock",
        "itemCondition": "https://schema.org/NewCondition"
      }
    };

    const faqSchema= {
      
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [
      {
      "@type": "Question",
      "name": "How much do "+serviceDetails[0].service_title+" services from Sourcedesk cost?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "To learn more about the pricing of our services, fill out the contact form, and we will be in touch to discuss pricing."
      }
      },
      {
      "@type": "Question",
      "name": "What if I am not happy with the services and want to exit the contract?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We provide a two-week free trial so that you can test before using our services. However, if you want to cancel the engagement after signing the contract, give us 30 days' notice."
        }
      },
      {
      "@type": "Question",
      "name": "How long have you been in business?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Sourcedesk has been operational for more than 12 years. We have provided software development services to more than 1100 clients."
      }
      },
      {
      "@type": "Question",
      "name": "Do you assign a project manager to each client?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We assign a dedicated project manager to keep you updated on your project's progress, ensuring everything is moving forward just the way you want it."
      }
      }
  ]
};


const breadcrmbSchema= {

  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [
  {
  "@type": "ListItem",
  "position": 1,
  "name": "Home",
  "item": "https://www.sourcedesk.io/"
  },
  {
  "@type": "ListItem",
  "position": 2,
  "name": serviceDetails[0].service_title,
  "item": currentUrl
  }
]
};

      return (
        <>
          {loading && <Loader />}
          {loading && (
            <section className="full_width Home_banner">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <h1>
                      <Skeleton />
                    </h1>
                    <p>
                      <Skeleton count={5} />
                    </p>
                    <p>
                      <Skeleton count={5} />
                    </p>
                  </div>
                </div>
              </div>
            </section>
          )}
          {!loading && (
            <div>
              <Helmet>
                <title>{`Sourcedesk: ${serviceDetails[0].service_meta_title} for Businesses of All Sizes`}</title>
                <meta
                  name="title"
                  content={`Sourcedesk: ${serviceDetails[0].service_meta_title} for Businesses of All Sizes`}
                />
                <meta
                  name="description"
                  content={`Simplify your business with Sourcedesk’s ${serviceDetails[0].service_meta_desc} services. Get expert web, mobile, and API solutions at affordable prices.`}
                />
                {/* <!-- Canonical & hrefLang --> */}
                <link rel="canonical" href={currentUrl} />
                <link rel="alternate" hrefLang="x-default" href={currentUrl} />
                <link rel="alternate" hrefLang="en-US" href={currentUrl} />

                {/* <!-- Twitter Card meta tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@sourcedeskgl" />
                <meta
                  name="twitter:title"
                  content={`Sourcedesk: ${serviceDetails[0].service_meta_title} for Businesses of All Sizes`}
                />
                <meta
                  name="twitter:description"
                  content={`Simplify your business with Sourcedesk’s ${serviceDetails[0].service_meta_desc} services. Get expert web, mobile, and API solutions at affordable prices.`}
                />
                <meta
                  name="twitter:image"
                  content="https://www.sourcedesk.io/public/images/Twitter-1200x600.jpg"
                />
                {/* <!-- Facebook Open Graph tags --> */}
                <meta property="og:url" content={currentUrl} />
                <meta property="og:type" content="website" />
                <meta
                  property="og:title"
                  content={`Sourcedesk: ${serviceDetails[0].service_meta_title} for Businesses of All Sizes`}
                />
                <meta
                  property="og:description"
                  content={`Simplify your business with Sourcedesk’s ${serviceDetails[0].service_meta_desc} services. Get expert web, mobile, and API solutions at affordable prices.`}
                />
                <meta
                  property="og:image"
                  content="https://www.sourcedesk.io/public/images/Fcebook-2400x1260.jpg"
                />
                {/* <!-- Additional Open Graph tags for better customization --> */}
                <meta property="og:image:width" content="2400" />
                <meta property="og:image:height" content="1260" />
                <meta property="og:image:alt" content="SourceDesk" />
                <meta property="og:locale" content="en_US" />

                {/* <!-- Title Bar Icon --> */}
                <link
                  rel="icon"
                  href="https://www.sourcedesk.io/public/images/favicon.png"
                  type="image/x-icon"
                />

                {/* <!-- Head End --> */}

                {/* Service Schema */} 
                <script type="application/ld+json">{JSON.stringify(serviceSchema)}</script>

                {/* faq Schema */}
                <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
                
                {/* breadcrumb Schema */}
                <script type="application/ld+json">{JSON.stringify(breadcrmbSchema)}</script>

              </Helmet>
              <BannerForm
                bannerTitle={`Simplify Your Business with ${serviceDetails[0].service_title} Services`}
                bannerParagraph={`Are you facing issues in finding ${serviceDetails[0].service_title} services? Look no further. At Sourcedesk, we have top-notch solutions for any business. Get the best service at affordable prices.`}
                primaryLinkTitle="Schedule Your Appointment"
                primaryLink="/schedule-a-call"
                secondaryLinkTitle=""
                secondaryLink=""
                bgImg={serviceDetails[0].service_banner_bg_img}
                bannerRightImg={serviceDetails[0].service_banner_img}
                bannerRightImgAlt=""
                primaryLinkInternal={false}
                secondaryLinkInternl={false}
                bgColor="#fafbfd"
                bgRepeat="no-repeat"
                pageUrl={currentUrl}
                service_title={serviceDetails[0].service_title}
              />
              <TrustedSlider defaultTitle={"Trusted By"} />
              <section className="full_width fix_padding">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 d-flex align-items-center justify-content-center">
                      <img
                        src={serviceDetails[0].service_second_section_img}
                        alt={serviceDetails[0].service_title}
                      />
                    </div>
                    <div className="col-md-6">
                      <h2 className="text_controller">
                        <span>{`Enhance Your Business Operations with ${serviceDetails[0].service_title}`}</span>
                        <hr />
                      </h2>
                      <p>
                        {`As a trusted ${serviceDetails[0].service_title}, we specialize in creating human-centric, results-driven solutions that drive innovation and boost efficiency for businesses of all sizes. Our team of expert developers is skilled in identifying transformative opportunities and overcoming challenges to deliver intelligent, data-driven solutions.`}
                      </p>
                      <p>
                        At Sourcedesk, we use cutting-edge digital tools and
                        technologies to craft custom solutions that align with
                        your needs. Our goal is to make your work process
                        easier. Our end-to-end services equip you to manage
                        disruption risks and adapt to the changing business
                        landscape with agility.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              {solutionsTabData.length > 0 ? (
                <section className="full_width fix_padding section-whats-included">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <div className="text_controller_center">
                          <h2>{`Our ${serviceDetails[0].service_title} Includes`}</h2>
                        </div>
                      </div>
                    </div>
                    <AboveScreenWidth setWidth={767}>
                      <div className="">
                        <Tab.Container defaultActiveKey={0}>
                          <Row>
                            <Col sm={5}>
                              <Nav variant="pills" className="flex-column me-3">
                                {solutionsTabData.map((e, i) => (
                                  <Nav.Item>
                                    <Nav.Link eventKey={i} as={"button"}>
                                      {e.tabHead}
                                    </Nav.Link>
                                  </Nav.Item>
                                ))}
                                {/* <Nav.Item>
                                    <Nav.Link eventKey="first">Tab 1</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                    <Nav.Link eventKey="second">Tab 2</Nav.Link>
                                    </Nav.Item> */}
                              </Nav>
                            </Col>
                            <Col sm={7}>
                              <Tab.Content className="ms-3">
                                {solutionsTabData.map((e, i) => (
                                  <Tab.Pane eventKey={i}>
                                    <div className="home_talent_tab">
                                      {/*<h3 className="text_controller">
                                        <span>{e.tabHead}</span>
                                      </h3>*/}
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: e.TabContent,
                                        }}
                                      ></div>
                                    </div>
                                  </Tab.Pane>
                                ))}
                                {/* <Tab.Pane eventKey="first">First tab content</Tab.Pane>
                                <Tab.Pane eventKey="second">Second tab content</Tab.Pane> */}
                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>
                      </div>
                    </AboveScreenWidth>
                    <BelowScreenWidth setWidth={768}>
                      <div className="">
                        <Accordion defaultActiveKey={0}>
                          {solutionsTabData.map((e, i) => (
                            <Accordion.Item eventKey={i}>
                              <Accordion.Header>{e.tabHead}</Accordion.Header>
                              <Accordion.Body
                                dangerouslySetInnerHTML={{
                                  __html: e.TabContent,
                                }}
                              ></Accordion.Body>
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      </div>
                    </BelowScreenWidth>
                  </div>
                </section>
              ) : (
                <div></div>
              )}

              {servicePlatform.length > 0 ? (
                <section className="section-technologies full_width fix_padding">
                  <div className="container">
                    <div className="row">
                      <div className="text_controller text_controller_center">
                        <p className="fs-heading-2">
                          Some Platforms
                          <br />
                          <span>Used by Our Experts</span>
                        </p>
                        <hr />
                      </div>
                    </div>

                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 justify-content-center gy-2 gy-sm-3 gy-md-4 gy-lg-5">
                      {servicePlatform.map((item, j) => (
                        <div className="col">
                          <div className="wrapper">
                            <p className="title">{item.cardTitle}</p>
                            <hr />
                            <p>{item.cardContent}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="row">
                      <div className="cmn_btn_grp cmn_btn_grp_center">
                        <NavLink to="/schedule-a-call" className="cmn_btn_fill">
                          Explore {serviceDetails[0].service_title}
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </section>
              ) : (
                <div></div>
              )}
              <section className="full_width fix_padding section-sdg-difference">
                <div className="container">
                  <div className="text_controller">
                    {/* <h2>
                      <span>
                        Empower Your Business’s Potential with Sourcedesk
                      </span>
                    </h2> */}
                    <h3 className="fs-heading-2">
                      <span>
                        Empower Your Business’s Potential with Sourcedesk
                      </span>
                    </h3>
                    <hr className="hr_black_bg" />
                    <p>Key Points that Set Us Apart</p>
                  </div>

                  <div className="row align-items-center">
                    <div className="col">
                      <ul>
                        <li>
                          <h4 className="">
                            <span>Customer-First Approach</span>
                          </h4>
                          <p>
                            At Sourcedesk, our clients are at the heart of
                            everything we do. We take the time to understand
                            where business owners are coming from and always
                            approach solutions with the end user in mind. This
                            allows us to address key challenges and create
                            solutions that will help your business thrive and
                            grow.
                          </p>
                        </li>

                        <li>
                          <h4 className="">
                            <span>Affordable Pricing</span>
                          </h4>
                          <p>
                            {`Our ${serviceDetails[0].service_title} services save you time and money by offering full-stack expertise, covering both front-end and back-end development. Our experts quickly troubleshoot and adjust to keep your project on track. Your dedicated team ensures seamless collaboration and an efficient, streamlined workflow.`}
                          </p>
                        </li>

                        <li>
                          <h4 className="">
                            <span>Expert-Led Custom Development</span>
                          </h4>
                          <p>
                            Our offshore experts bring hands-on experience to
                            every stage of the custom development process,
                            allowing them to lead projects with confidence and
                            introduce innovations for a more efficient final
                            product. Their deep understanding of industry trends
                            enables them to tackle unexpected challenges, no
                            matter how complex.
                          </p>
                        </li>

                        <li>
                          <h4 className="">
                            <span>Better Scalability</span>
                          </h4>
                          <p>
                            We’re here to help your business grow without the
                            hassle of major changes to your applications. Our
                            solutions ensure your systems can handle increased
                            traffic and demand to keep your customers happy.
                            Plus, we make it simple to add new features and
                            functionality so your business can keep evolving
                            with ease.
                          </p>
                        </li>
                        <li>
                          <h4 className="">
                            <span>Support and Maintenance</span>
                          </h4>
                          <p>
                            Our experts are skilled in a wide range of
                            technologies and stay updated with the latest
                            industry trends. This means they can quickly adapt
                            to changing environments and deliver the best
                            solutions for your business. They’re also able to
                            make swift adjustments to enhance your product and
                            keep it ahead of the curve.
                          </p>
                        </li>
                        <li>
                          <h4 className="">
                            <span>Flexibility</span>
                          </h4>
                          <p>
                            We are an agile offshore software development
                            company that excels in adapting to different
                            environments and working with a wide range of coding
                            languages. With expertise in both frontend and
                            backend development, we take your projects from
                            concept to market-ready smoothly and efficiently,
                            delivering high-quality results with speed and
                            precision.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
              {serviceCasestudy.length > 0 ? (
                <section className="section-case-study fix_padding full_width">
                  <div className="container">
                    <div className="text_controller text_controller_center">
                      <h3 className="mb-0 fs-heading-2">Case Study</h3>
                      <hr />
                    </div>

                    <CaseStudySlider caseStudyarray={serviceCasestudy} />
                  </div>
                </section>
              ) : (
                <div></div>
              )}
              <section
                className="developer-cta section-cta-horizontal fix_padding full_width"
                style={{
                  background: `url(${window.location.origin}/images/ai-ml-development-cta-horizontal-bg.jpg) center/cover no-repeat`,
                }}
              >
                <div className="container">
                  <div className="row align-items-center gy-4">
                    <div className="col-sm-8">
                      <p className="title">{`Begin Your ${serviceDetails[0].service_title} Project Now`}</p>
                    </div>
                    <div className="col-sm-4">
                      <NavLink
                        to="/schedule-a-call"
                        className="cmn_btn_fill cmn_btn_fill_white text-nowrap d-inline-block"
                      >
                        Get Started
                      </NavLink>
                    </div>
                  </div>
                </div>
              </section>
              <section className="section-industries full_width fix_padding">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <div className="text_controller text_controller_center">
                        <p className="fs-heading-2">
                          Our Collaborative Sectors
                        </p>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <ul className="make-borders">
                        <li>
                          <div className="wrapper">
                            <div className="wrapper__img">
                              <img
                                src={
                                  window.location.origin +
                                  "/images/healthcare.png"
                                }
                                alt=""
                              />
                            </div>
                            <p>Healthcare</p>
                          </div>
                        </li>
                        <li>
                          <div className="wrapper">
                            <div className="wrapper__img">
                              <img
                                src={
                                  window.location.origin + "/images/finance.png"
                                }
                                alt=""
                              />
                            </div>
                            <p>Finance</p>
                          </div>
                        </li>
                        <li>
                          <div className="wrapper">
                            <div className="wrapper__img">
                              <img
                                src={
                                  window.location.origin + "/images/retail.png"
                                }
                                alt=""
                              />
                            </div>
                            <p>Retail</p>
                          </div>
                        </li>
                        <li>
                          <div className="wrapper">
                            <div className="wrapper__img">
                              <img
                                src={
                                  window.location.origin +
                                  "/images/automobile.png"
                                }
                                alt=""
                              />
                            </div>
                            <p>Automotive</p>
                          </div>
                        </li>
                        <li>
                          <div className="wrapper">
                            <div className="wrapper__img">
                              <img
                                src={
                                  window.location.origin +
                                  "/images/manufacturing.png"
                                }
                                alt=""
                              />
                            </div>
                            <p>Manufacturing</p>
                          </div>
                        </li>
                        <li>
                          <div className="wrapper">
                            <div className="wrapper__img">
                              <img
                                src={
                                  window.location.origin +
                                  "/images/marketing-advertising.png"
                                }
                                alt=""
                              />
                            </div>
                            <p>
                              Marketing
                              <br />& Advertising
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="wrapper">
                            <div className="wrapper__img">
                              <img
                                src={
                                  window.location.origin +
                                  "/images/telecommunications.png"
                                }
                                alt=""
                              />
                            </div>
                            <p>Telecommunications</p>
                          </div>
                        </li>
                        <li>
                          <div className="wrapper">
                            <div className="wrapper__img">
                              <img
                                src={
                                  window.location.origin +
                                  "/images/agriculture.png"
                                }
                                alt=""
                              />
                            </div>
                            <p>Agriculture</p>
                          </div>
                        </li>
                        <li>
                          <div className="wrapper">
                            <div className="wrapper__img">
                              <img
                                src={
                                  window.location.origin + "/images/energy.png"
                                }
                                alt=""
                              />
                            </div>
                            <p>Energy</p>
                          </div>
                        </li>
                        <li>
                          <div className="wrapper">
                            <div className="wrapper__img">
                              <img
                                src={
                                  window.location.origin +
                                  "/images/education.png"
                                }
                                alt=""
                              />
                            </div>
                            <p>Education</p>
                          </div>
                        </li>
                        <li>
                          <div className="wrapper">
                            <div className="wrapper__img">
                              <img
                                src={
                                  window.location.origin +
                                  "/images/transportation-logistics.png"
                                }
                                alt=""
                              />
                            </div>
                            <p>
                              Transportation
                              <br />& Logistics
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="wrapper">
                            <div className="wrapper__img">
                              <img
                                src={
                                  window.location.origin +
                                  "/images/government.png"
                                }
                                alt=""
                              />
                            </div>
                            <p>Government</p>
                          </div>
                        </li>
                        <li>
                          <div className="wrapper">
                            <div className="wrapper__img">
                              <img
                                src={
                                  window.location.origin +
                                  "/images/media-entertainment.png"
                                }
                                alt=""
                              />
                            </div>
                            <p>
                              Media &
                              <br />
                              Entertainment
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="wrapper">
                            <div className="wrapper__img">
                              <img
                                src={
                                  window.location.origin +
                                  "/images/insurance.png"
                                }
                                alt=""
                              />
                            </div>
                            <p>Insurance</p>
                          </div>
                        </li>
                        <li>
                          <div className="wrapper">
                            <div className="wrapper__img">
                              <img
                                src={
                                  window.location.origin +
                                  "/images/real-estate.png"
                                }
                                alt=""
                              />
                            </div>
                            <p>Real Estate</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
              <div className="clear"></div>
              <section class="developer-faqs full_width fix_padding developer-faqs__bg-1">
                <div class="container">
                  <div class="row">
                    <div class="col">
                      <div class="text_controller">
                        <p className="fs-heading-2">FAQ</p>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="stppng_accordian">
                        <Accordion defaultActiveKey={0}>
                          {FAQsData.map((e, i) => (
                            <Accordion.Item eventKey={i}>
                              <Accordion.Header as="div">
                                {e.faq}
                              </Accordion.Header>
                              <Accordion.Body>{e.ans}</Accordion.Body>
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div class="clear"></div>
              <section
                className="section-testimonials full-width fix_padding"
                style={{
                  background: `url(${window.location.origin}/images/ai-ml-development-testimony-bg.jpg) center/cover no-repeat`,
                }}
              >
                <div className="container">
                  <div className="row align-items-center gy-5">
                    <div className="col-md-4">
                      <div className="text_controller">
                        <p className="fs-heading-2">
                          <span>Testimonials</span>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <TestimonialsSlider
                        serviceTitle={serviceDetails[0].service_title}
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section class="section-cta-form-full full-width fix_padding">
                <div class="container">
                  <div class="row mb-5">
                    <div class="col">
                      <div class="text_controller text_controller_center">
                        <h4 className="fs-heading-2">
                          <span>{`Do You Have a ${serviceDetails[0].service_title} Project?`}</span>
                        </h4>
                        <hr />
                        <p>
                          Fill out the form and schedule a call with our experts
                          to find the best solution for your project.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row mx-md-5 px-md-5 mx-sm-2 px-sm-2">
                    <form
                      id="webform6057464000001083017"
                      action="https://crm.zoho.com/crm/WebToLeadForm"
                      name="WebToLeads6057464000001083017"
                      method="POST"
                      accept-charset="UTF-8"
                    >
                      {/*  <!-- Do not remove this code. --> */}
                      <input
                        type="text"
                        style={{ display: "none" }}
                        name="xnQsjsdp"
                        value="7e1fcdb34e2a213ab3ba38616659a51d379b6c0fe4b7384d7403655a5defc861"
                      ></input>
                      <input
                        type="hidden"
                        name="zc_gad"
                        id="zc_gad"
                        value=""
                      ></input>
                      <input
                        type="text"
                        style={{ display: "none" }}
                        name="xmIwtLD"
                        value="c0a38f9f3329b3a20dd6c13074c4dada3eb9daa6ea764f932498c473e929194dc519d95864df6fc0443107dfd5c400c9"
                      ></input>
                      <input
                        type="text"
                        style={{ display: "none" }}
                        name="actionType"
                        value="TGVhZHM="
                      ></input>
                      <input
                        type="text"
                        style={{ display: "none" }}
                        name="returnURL"
                        value="https://www.sourcedesk.io/thank-you?thank-you=2"
                      ></input>

                      <div class="col">
                        <div class="row">
                          <div class="col-md-6">
                            <label for="name" class="if__required">
                              FIRST NAME
                            </label>
                            <input
                              type="text"
                              class="w-100"
                              id="First_Name"
                              name="First Name"
                              pattern="[a-zA-Z\s]*"
                              title="Please use only alphabets"
                              required
                            />
                          </div>
                          <div class="col-md-6">
                            <label for="name" class="if__required">
                              LAST NAME
                            </label>
                            <input
                              type="text"
                              class="w-100"
                              id="Last_Name"
                              name="Last Name"
                              pattern="[a-zA-Z\s]*"
                              title="Please use only alphabets"
                              required
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <label for="email" class="if__required">
                              EMAIL
                            </label>
                            <input
                              type="email"
                              class="w-100"
                              id="Email"
                              name="Email"
                              required
                            />
                          </div>
                          <div class="col-md-6">
                            <label for="phone" class="if__required">
                              PHONE NO.
                            </label>
                            <input
                              type="tel"
                              class="w-100"
                              id="Phone"
                              name="Phone"
                              maxlength="10"
                              minlength="10"
                              pattern="^[0-9]+$"
                              title="Please use only numbers"
                              required
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <label for="company-name" class="if__required">
                              COMPANY NAME
                            </label>
                            <input
                              type="text"
                              class="w-100"
                              id="Company"
                              name="Company"
                              required
                            />
                          </div>
                          <div class="col-md-6">
                            <label for="website">COMPANY WEBSITE</label>{" "}
                            <input
                              type="text"
                              class="w-100"
                              id="LEADCF16"
                              name="LEADCF16"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <label for="message" class="if__required">
                              MESSAGE
                            </label>
                            <textarea
                              id="Description"
                              name="Description"
                              cols="30"
                              rows="10"
                              class="w-100"
                              required
                            ></textarea>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col text-center">
                            <input type="submit" value="Submit" />
                          </div>
                        </div>
                      </div>
                      <div style={{ display: "none" }}>
                        <div class="zcwf_row wfrm_fld_dpNn">
                          <div
                            class="zcwf_col_lab"
                            style={{ fontSize: "12px", fontFamily: "Arial" }}
                          >
                            <label for="LEADCF1">Enquiry Type</label>
                          </div>
                          <div class="zcwf_col_fld">
                            <select
                              class="zcwf_col_fld_slt"
                              id="LEADCF1"
                              name="LEADCF1"
                            >
                              <option value="-None-">-None-</option>
                              <option value="VBK Sales Flow">
                                VBK Sales Flow
                              </option>
                              <option value="SEO-PPC-Website Development Flow">
                                SEO-PPC-Website Development Flow
                              </option>
                              <option value="CDAP Sales Flow">
                                CDAP Sales Flow
                              </option>
                              <option selected="" value="SourceDeskio Leads">
                                SourceDeskio Leads
                              </option>
                            </select>
                            <div class="zcwf_col_help"></div>
                          </div>
                        </div>
                        <div class="zcwf_row wfrm_fld_dpNn">
                          <div
                            class="zcwf_col_lab"
                            style={{ fontSize: "12px", fontFamily: "Arial" }}
                          >
                            <label for="Lead_Source">Lead Source</label>
                          </div>
                          <div class="zcwf_col_fld">
                            <select
                              class="zcwf_col_fld_slt"
                              id="Lead_Source"
                              name="Lead Source"
                            >
                              <option value="-None-">-None-</option>
                              <option selected="" value="Website">
                                Website
                              </option>
                              <option value="Google Search">
                                Google Search
                              </option>
                              <option value="Chat">Chat</option>
                              <option value="Word of Mouth">
                                Word of Mouth
                              </option>
                              <option value="Facebook">Facebook</option>
                              <option value="Instagram">Instagram</option>
                              <option value="Inbound Email">
                                Inbound Email
                              </option>
                              <option value="Inbound Call">Inbound Call</option>
                            </select>
                            <div class="zcwf_col_help"></div>
                          </div>
                        </div>
                        <div class="zcwf_row wfrm_fld_dpNn">
                          <div
                            class="zcwf_col_lab"
                            style={{ fontSize: "12px", fontFamily: "Arial" }}
                          >
                            <label for="Lead_Status">Lead Status</label>
                          </div>
                          <div class="zcwf_col_fld">
                            <select
                              class="zcwf_col_fld_slt"
                              id="Lead_Status"
                              name="Lead Status"
                            >
                              <option value="-None-">-None-</option>
                              <option selected="" value="Not Contacted">
                                Not Contacted
                              </option>
                              <option value="Contacted">Contacted</option>
                              <option value="Meeting Scheduled">
                                Meeting Scheduled
                              </option>
                              <option value="Interested">Interested</option>
                              <option value="Junk Lead">Junk Lead</option>
                              <option value="Lost Lead">Lost Lead</option>
                              <option value="Pre-Qualified">
                                Pre-Qualified
                              </option>
                            </select>
                            <div class="zcwf_col_help"></div>
                          </div>
                        </div>
                        <div class="zcwf_row wfrm_fld_dpNn">
                          <div
                            class="zcwf_col_lab"
                            style={{ fontSize: "12px", fontFamily: "Arial" }}
                          >
                            <label for="LEADCF17">Submitted from page</label>
                          </div>
                          <div class="zcwf_col_fld">
                            <input
                              type="text"
                              id="LEADCF17"
                              name="LEADCF17"
                              maxlength="255"
                              value="https://www.sourcedesk.io/ai-ml-development"
                            />
                            <div class="zcwf_col_help"></div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </section>
            </div>
          )}
        </>
      );
    } else {

      const schemaData= {
      
        "@context": "https://schema.org",
        "@type": "Service",
        "name": serviceDetails[0].service_title,
        "url": currentUrl,
        "description": "Simplify your business with Sourcedesk’s "+serviceDetails[0].service_title+" services. Get expert web, mobile, and API solutions at affordable prices.",
        "provider": {
            "@type": "Organization",
            "name": "Sourcedesk",
            "url": "https://www.sourcedesk.io/"
        },
        "areaServed": {
            "@type": "Country",
            "name": "Global"
        },
        "serviceType": serviceDetails[0].service_title,
        "offers": {
          "@type": "Offer",
          "price": "Request a Quote",
          "priceCurrency": "USD",
          "availability": "https://schema.org/InStock",
          "url": "https://www.sourcedesk.io/contact"
        }

    };



const breadcrmbSchema= {

  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [
  {
  "@type": "ListItem",
  "position": 1,
  "name": "Home",
  "item": "https://www.sourcedesk.io/"
  },
  {
  "@type": "ListItem",
  "position": 2,
  "name": serviceDetails[0].service_title,
  "item": currentUrl
  }
]
};
      return (
        <>
          {loading && <Loader />}
          {loading && (
            <section className="full_width Home_banner">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <h1>
                      <Skeleton />
                    </h1>
                    <p>
                      <Skeleton count={5} />
                    </p>
                    <p>
                      <Skeleton count={5} />
                    </p>
                  </div>
                </div>
              </div>
            </section>
          )}
          {!loading && (
            <div>
              <Helmet>
                <title>{serviceDetails[0].service_meta_title}</title>
                <meta
                  name="title"
                  content={serviceDetails[0].service_meta_title}
                />
                <meta
                  name="description"
                  content={serviceDetails[0].service_meta_desc}
                />
                {/* <!-- Canonical & hrefLang --> */}
                <link rel="canonical" href={currentUrl} />
                <link rel="alternate" hrefLang="x-default" href={currentUrl} />
                <link rel="alternate" hrefLang="en-US" href={currentUrl} />

                {/* <!-- Twitter Card meta tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@sourcedeskgl" />
                <meta
                  name="twitter:title"
                  content={serviceDetails[0].service_meta_title}
                />
                <meta
                  name="twitter:description"
                  content={serviceDetails[0].service_meta_title}
                />
                <meta
                  name="twitter:image"
                  content="https://www.sourcedesk.io/public/images/Twitter-1200x600.jpg"
                />
                {/* <!-- Facebook Open Graph tags --> */}
                <meta property="og:url" content={currentUrl} />
                <meta property="og:type" content="website" />
                <meta
                  property="og:title"
                  content={serviceDetails[0].service_meta_title}
                />
                <meta
                  property="og:description"
                  content={serviceDetails[0].service_meta_title}
                />
                <meta
                  property="og:image"
                  content="https://www.sourcedesk.io/public/images/Fcebook-2400x1260.jpg"
                />
                {/* <!-- Additional Open Graph tags for better customization --> */}
                <meta property="og:image:width" content="2400" />
                <meta property="og:image:height" content="1260" />
                <meta property="og:image:alt" content="SourceDesk" />
                <meta property="og:locale" content="en_US" />

                {/* Service Schema */} 
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
                {/* breadcrumb Schema */}
                <script type="application/ld+json">{JSON.stringify(breadcrmbSchema)}</script>

              </Helmet>
              <InnerBanner
                InnBanner={serviceDetails[0].service_banner_bg_img}
                bgRepeat="no-repeat"
                bgColor="#fafbfd"
                BnrRightImg={serviceDetails[0].service_banner_img}
                TitleInn={[
                  <span>
                    {serviceDetails[0].service_title} is simply dummy text of
                    the printing and typesetting industry.
                  </span>,
                ]}
                ParaInn="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                priCTALink="/schedule-a-call"
                priCTATitle="Lorem Ipsum"
                secCTALink={""}
                secCTATitle={""}
              />
              <TrustedSlider defaultTitle={"Trusted By"} />
              <section className="full_width fix_padding section-why-sdg-is-best mt-40">
                <div className="container">
                  <div className="text_controller text_controller_center">
                    <h2 className="text-controller">
                      {" "}
                      <span>
                        Why Sourcedesk Is Your Best Choice for Hiring Top-Tier
                        AI/ML Developers
                      </span>{" "}
                    </h2>
                    <p>
                      {" "}
                      At Sourcedesk, we meticulously select the finest AI and
                      machine learning developers to power your project's
                      success. Our rigorous vetting ensures that only the most
                      qualified professionals are matched to your organization's
                      specific needs.{" "}
                    </p>
                    <hr />
                  </div>
                  <div className="row mt-40 align-items-center">
                    <div className="col-md-6 col-sm-6">
                      <div className="">
                        {" "}
                        <img
                          src={serviceDetails[0].service_second_section_img}
                          alt={serviceDetails[0].service_title}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="stppng_accordian" id="accordionExample">
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              Dedicated Professionals
                            </Accordion.Header>
                            <Accordion.Body>
                              {" "}
                              Our AI/ML developers are passionate experts
                              committed to delivering exceptional work. They
                              seamlessly integrate with your team, aligning with
                              your business objectives.{" "}
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              AI-Driven Matchmaking
                            </Accordion.Header>
                            <Accordion.Body>
                              {" "}
                              Recognizing each organization's unique needs, our
                              AI-powered capabilities, combined with human
                              expertise, enable us to identify the perfect AI/ML
                              professionals for your project.{" "}
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>
                              Successful Delivery
                            </Accordion.Header>
                            <Accordion.Body>
                              {" "}
                              Equipped with the latest tools and technologies,
                              our AI/ML developers ensure successful project
                              completion. We boast a 100% success rate in
                              building high-performing teams that drive the
                              future of the businesses they serve.{" "}
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>
                              Rigorous Vetting
                            </Accordion.Header>
                            <Accordion.Body>
                              {" "}
                              Our vetting process for AI/ML developers is
                              comprehensive. We meticulously evaluate their
                              coding skills, language proficiency, and cultural
                              fit to ensure they are the perfect match for your
                              project.{" "}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <DevProfiles
                priCTALink="/"
                priCTATitle={"Build Your Team Now"}
                heading={"Lorem Ipsum is simply dummy text of the printing"}
              />
              <section className="full_width fix_padding section-technical-expertise">
                <div className="container">
                  <div className="text_controller text_controller_center">
                    <h2>
                      {" "}
                      Unparalleled AI/ML Expertise
                      <br />
                      on <span>Demand</span>{" "}
                    </h2>
                    <hr />
                  </div>
                  <ul className="uniq_infrmtn_down">
                    <li>
                      <div className="uniq_infrmtn_left">
                        <h3>APIs</h3>
                      </div>
                      <div className="uniq_infrmtn_right">
                        {" "}
                        DataRobot API | IBM Watson API | Amazon SageMaker API |
                        H2O.ai API | TensorFlow API | PyTorch API | Google Cloud
                        AI API | Azure Machine Learning API | Databricks API{" "}
                      </div>
                    </li>
                    <li>
                      <div className="uniq_infrmtn_left">
                        <h3>Frameworks</h3>
                      </div>
                      <div className="uniq_infrmtn_right">
                        {" "}
                        Apache Spark | H2O.ai | Microsoft Cognitive Toolkit |
                        TensorFlow | IBM Watson | Amazon SageMaker | Google
                        Cloud AI Platform | PyTorch | Toolkit | Azure Machine
                        Learning | Databricks | DataRobot{" "}
                      </div>
                    </li>
                    <li>
                      <div className="uniq_infrmtn_left">
                        <h3>Libraries</h3>
                      </div>
                      <div className="uniq_infrmtn_right">
                        {" "}
                        PyTorch | Scikit-learn | Keras | Pandas | NumPy |
                        Matplotlib | NLTK | OpenCV | TensorFlow | Hugging Face
                        Transformers | FastAI | Spark NLP | Dask | Prophet{" "}
                      </div>
                    </li>
                    <li>
                      <div className="uniq_infrmtn_left">
                        <h3>Databases</h3>
                      </div>
                      <div className="uniq_infrmtn_right">
                        {" "}
                        TensorFlow Extended (TFX) | MLflow | Feast | Polyaxon |
                        Kubeflow | Apache Airflow | DVC | Pachyderm | Seldon
                        Core | KubeFlow{" "}
                      </div>
                    </li>
                    <li>
                      <div className="uniq_infrmtn_left">
                        <h3>Platforms</h3>
                      </div>
                      <div className="uniq_infrmtn_right">
                        {" "}
                        Amazon Web Services (AWS) | Google Cloud Platform (GCP)
                        | Microsoft Azure | IBM Cloud | Databricks | DataRobot |
                        H2O.ai | Alibaba Cloud | Oracle Cloud | SAP Leonardo{" "}
                      </div>
                    </li>
                  </ul>
                  <div className="cmn_btn_grp cmn_btn_grp_center cmn_btn_exchange cmn_btn_wide">
                    {" "}
                    <NavLink to="/schedule-a-call" className="cmn_btn_stroke">
                      Recruit Top AI/ML Talent
                    </NavLink>{" "}
                    <NavLink
                      to="/hire-development-team"
                      className="cmn_btn_fill"
                    >
                      Build Your Dream Engineering Team
                    </NavLink>{" "}
                  </div>
                </div>
              </section>
              <section
                class="full_width bg_dark_blue fix_padding"
                style={{ background: "#1b212d" }}
              >
                <div class="container">
                  <div class="text_controller">
                    <h2>
                      Streamlined Process to Hire Our Elite Remote{" "}
                      <span> ReactJS Developers</span>
                    </h2>
                    <hr />
                  </div>
                  <div class="row">
                    <div class="col-sm-6 col-md-6 react_ft_cnt">
                      <ul>
                        <li>
                          <h3>Lorem, ipsum dolor.</h3>
                          <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing
                            elit. Natus, aut.
                          </p>
                        </li>
                        <li>
                          <h3>Lorem, ipsum dolor.</h3>
                          <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing
                            elit. Natus, aut.
                          </p>
                        </li>
                        <li>
                          <h3>Lorem, ipsum dolor.</h3>
                          <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing
                            elit. Natus, aut.
                          </p>
                        </li>
                        <li>
                          <h3>Lorem, ipsum dolor.</h3>
                          <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing
                            elit. Natus, aut.
                          </p>
                        </li>
                      </ul>
                      <div class="cmn_btn_grp">
                        {" "}
                        <a
                          href="https://www.sourcedesk.io/schedule-a-call"
                          class="cmn_btn_fill"
                        >
                          Lorem, ipsum dolor.
                        </a>{" "}
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6">
                      <div class="react_ft_img">
                        {" "}
                        <img
                          src={serviceDetails[0].service_bottom_section_img}
                          alt={serviceDetails[0].service_title}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section class="section-cta-form-full full-width fix_padding">
                <div class="container">
                  <div class="row mb-5">
                    <div class="col">
                      <h2 class="text_controller text_controller_center">
                        {" "}
                        <span>
                          Revolutionize Your Business with Sourcedesk's AI and
                          ML Solutions
                        </span>
                        <br />{" "}
                        <span>
                          Unlock Unprecedented Efficiency and ROI – Schedule a
                          Consultation with Our Experts
                        </span>{" "}
                      </h2>
                      <div class="text_controller text_controller_center">
                        <hr />
                        <h3>
                          {" "}
                          Schedule a Call With Our AI Experts to Create Robust
                          and Real-Time AI and ML Solutions{" "}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="row mx-md-5 px-md-5 mx-sm-2 px-sm-2">
                    <form
                      action="https://crm.zoho.com/crm/WebToLeadForm"
                      name="WebToLeads6057464000001083017"
                      method="POST"
                    >
                      <input
                        type="hidden"
                        name="_token"
                        value="i5mkriFaXQX1URII7PPA3thM9jt2FRxobCT4AUZu"
                      ></input>
                      <input
                        type="hidden"
                        name="xnQsjsdp"
                        value="7e1fcdb34e2a213ab3ba38616659a51d379b6c0fe4b7384d7403655a5defc861"
                      ></input>
                      <input
                        type="hidden"
                        name="zc_gad"
                        id="zc_gad"
                        value=""
                      ></input>
                      <input
                        type="hidden"
                        name="xmIwtLD"
                        value="c0a38f9f3329b3a20dd6c13074c4dada3eb9daa6ea764f932498c473e929194dc519d95864df6fc0443107dfd5c400c9"
                      ></input>
                      <input
                        type="hidden"
                        name="actionType"
                        value="TGVhZHM="
                      ></input>
                      <input
                        type="hidden"
                        name="returnURL"
                        value="https://www.sourcedesk.io/thank-you?thank-you=2"
                      ></input>
                      <div class="col">
                        <div class="row">
                          <div class="col-md-6">
                            {" "}
                            <label for="name" class="if__required">
                              FIRST NAME
                            </label>{" "}
                            <input
                              type="text"
                              class="w-100"
                              id="First_Name"
                              name="First Name"
                              pattern="[a-zA-Z\s]*"
                              title="Please use only alphabets"
                              required
                            />{" "}
                          </div>
                          <div class="col-md-6">
                            {" "}
                            <label for="name" class="if__required">
                              LAST NAME
                            </label>{" "}
                            <input
                              type="text"
                              class="w-100"
                              id="Last_Name"
                              name="Last Name"
                              pattern="[a-zA-Z\s]*"
                              title="Please use only alphabets"
                              required
                            />{" "}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            {" "}
                            <label for="email" class="if__required">
                              EMAIL
                            </label>{" "}
                            <input
                              type="email"
                              class="w-100"
                              id="Email"
                              name="Email"
                              required
                            />{" "}
                          </div>
                          <div class="col-md-6">
                            {" "}
                            <label for="phone" class="if__required">
                              PHONE NO.
                            </label>{" "}
                            <input
                              type="tel"
                              class="w-100"
                              id="Phone"
                              name="Phone"
                              maxlength="10"
                              minlength="10"
                              pattern="^[0-9]+$"
                              title="Please use only numbers"
                              required
                            />{" "}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            {" "}
                            <label for="company-name" class="if__required">
                              COMPANY NAME
                            </label>{" "}
                            <input
                              type="text"
                              class="w-100"
                              id="Company"
                              name="Company"
                              required
                            />{" "}
                          </div>
                          <div class="col-md-6">
                            {" "}
                            <label for="website">COMPANY WEBSITE</label>{" "}
                            <input
                              type="text"
                              class="w-100"
                              id="LEADCF16"
                              name="LEADCF16"
                            />{" "}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            {" "}
                            <label for="message" class="if__required">
                              MESSAGE
                            </label>{" "}
                            <textarea
                              id="Description"
                              name="Description"
                              cols="30"
                              rows="10"
                              class="w-100"
                              required
                            ></textarea>{" "}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col text-center">
                            {" "}
                            <input type="submit" value="Submit" />{" "}
                          </div>
                        </div>
                      </div>
                      <div style={{ display: "none" }}>
                        <div class="zcwf_row wfrm_fld_dpNn">
                          <div
                            class="zcwf_col_lab"
                            style={{ fontSize: "12px", fontFamily: "Arial" }}
                          >
                            <label for="LEADCF1">Enquiry Type</label>
                          </div>
                          <div class="zcwf_col_fld">
                            <select
                              class="zcwf_col_fld_slt"
                              id="LEADCF1"
                              name="LEADCF1"
                            >
                              <option value="-None-">-None-</option>
                              <option value="VBK Sales Flow">
                                VBK Sales Flow
                              </option>
                              <option value="SEO-PPC-Website Development Flow">
                                SEO-PPC-Website Development Flow
                              </option>
                              <option value="CDAP Sales Flow">
                                CDAP Sales Flow
                              </option>
                              <option selected="" value="SourceDeskio Leads">
                                SourceDeskio Leads
                              </option>
                            </select>
                            <div class="zcwf_col_help"></div>
                          </div>
                        </div>
                        <div class="zcwf_row wfrm_fld_dpNn">
                          <div
                            class="zcwf_col_lab"
                            style={{ fontSize: "12px", fontFamily: "Arial" }}
                          >
                            <label for="Lead_Source">Lead Source</label>
                          </div>
                          <div class="zcwf_col_fld">
                            <select
                              class="zcwf_col_fld_slt"
                              id="Lead_Source"
                              name="Lead Source"
                            >
                              <option value="-None-">-None-</option>
                              <option selected="" value="Website">
                                Website
                              </option>
                              <option value="Google Search">
                                Google Search
                              </option>
                              <option value="Chat">Chat</option>
                              <option value="Word of Mouth">
                                Word of Mouth
                              </option>
                              <option value="Facebook">Facebook</option>
                              <option value="Instagram">Instagram</option>
                              <option value="Inbound Email">
                                Inbound Email
                              </option>
                              <option value="Inbound Call">Inbound Call</option>
                            </select>
                            <div class="zcwf_col_help"></div>
                          </div>
                        </div>
                        <div class="zcwf_row wfrm_fld_dpNn">
                          <div
                            class="zcwf_col_lab"
                            style={{ fontSize: "12px", fontFamily: "Arial" }}
                          >
                            <label for="Lead_Status">Lead Status</label>
                          </div>
                          <div class="zcwf_col_fld">
                            <select
                              class="zcwf_col_fld_slt"
                              id="Lead_Status"
                              name="Lead Status"
                            >
                              <option value="-None-">-None-</option>
                              <option selected="" value="Not Contacted">
                                Not Contacted
                              </option>
                              <option value="Contacted">Contacted</option>
                              <option value="Meeting Scheduled">
                                Meeting Scheduled
                              </option>
                              <option value="Interested">Interested</option>
                              <option value="Junk Lead">Junk Lead</option>
                              <option value="Lost Lead">Lost Lead</option>
                              <option value="Pre-Qualified">
                                Pre-Qualified
                              </option>
                            </select>
                            <div class="zcwf_col_help"></div>
                          </div>
                        </div>
                        <div class="zcwf_row wfrm_fld_dpNn">
                          <div
                            class="zcwf_col_lab"
                            style={{ fontSize: "12px", fontFamily: "Arial" }}
                          >
                            {" "}
                            <label for="LEADCF17">Submitted from page</label>
                          </div>
                          <div class="zcwf_col_fld">
                            <input
                              type="text"
                              id="LEADCF17"
                              name="LEADCF17"
                              maxlength="255"
                              value="https://www.sourcedesk.io/ai-ml-development"
                            />
                            <div class="zcwf_col_help"></div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </section>
              <section
                class="full_width foot_gap pricing_pgbk fix_padding"
                style={{
                  background:
                    "url(" +
                    window.location.origin +
                    '"/images/cta-bg-sketch-artist.jpg") 50% 50% no-repeat rgb(27, 33, 45)',
                }}
              >
                <div class="container">
                  <h2>Lorem ipsum dolor sit amet.</h2>
                  <div class="cmn_btn_grp cmn_btn_grp_center">
                    <a href="/" class="cmn_btn_stroke">
                      Lorem, ipsum.
                    </a>
                  </div>
                </div>
              </section>
            </div>
          )}
        </>
      );
    }
  } else {
    return (
      <>
        <section class="full_width Home_banner">
          <div class="container">
            <div className="row">
              <div className="col">
                {loading && <Loader />}
                {loading && (
                  <div>
                    <h1>
                      <Skeleton />
                    </h1>
                    <p>
                      <Skeleton count={5} />
                    </p>
                    <p>
                      <Skeleton count={5} />
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
const CaseStudySlider = ({ caseStudyarray }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <button></button>,
  };
  if (caseStudyarray.length) {
    return (
      <>
        <div className="thoughts">
          <Slider {...settings}>
            {caseStudyarray.map((item, i) => (
              <div className="case-study-slider">
                <div className="container case-study-slide">
                  <div className="row mb-40 gx-sm-2 gx-md-3 gx-lg-4">
                    <div className="col-sm-2 d-flex align-items-sm-center justify-content-sm-center">
                      <div className="case-study-comp-img d-sm-flex align-items-sm-center justify-content-sm-center">
                        <img src={item.photo_icon} alt={item.project_title} />
                      </div>
                    </div>
                    <div className="col-sm-10 about-project">
                      <h4 className="mb-1">
                        <span>Project :</span> {item.project_title}
                      </h4>
                      <p className="mb-1">
                        <span>Description :</span> {item.project_description}
                      </p>
                      <p className="mb-0">
                        <span>Implementation Time :</span>{" "}
                        {item.implementation_time}
                      </p>
                    </div>
                  </div>

                  {item.benefits_list.length > 0 ? (
                    <div className="row align-items-center">
                      <div className="col">
                        {/* <div className="row gx-1 gx-lg-2 gx-xl-3 gx-xxl-4 row-cols-1 row-cols-sm-3 row-cols-md-5 details-project gy-1"> */}
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 gy-2 gx-2 details-project">
                          {item.benefits_list.map((item2, j) => (
                            <div className="col">
                              <div className="details-col">
                                <p>{item2.benefit_desc}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </>
    );
  }
};

const TestimonialsSlider = ({ serviceTitle }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          dots: true,
          arrows: false,
        },
      },
    ],
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <button></button>,
  };
  return (
    <div className="slider-testimonials" id="cmn_slider_btn">
      <Slider {...settings}>
        <div className="slider-testimonials-slides">
          <p>
            "Sourcedesk's {serviceTitle} Service is truly top-notch. Their
            team's expertise, combined with their agile development process,
            allowed us to quickly prototype and iterate on AI/ML models to find
            the best fit for our unique challenges. Their transparent
            communication and collaborative approach made them feel like an
            extension of our own team, and their solutions have unlocked new
            opportunities for growth and innovation within our organization."
          </p>
          <div className="author">
            <p>Samantha Lee</p>
            <p>Head of AI Strategy</p>
          </div>
          <div className="ratings">
            {Array.from({ length: 5 }, (_, i) => (
              <i
                class="fa fa-star"
                aria-hidden="true"
                style={{ color: "#ffd43b", width: "20px" }}
              ></i>
            ))}
          </div>
        </div>
        <div className="slider-testimonials-slides">
          <p>
            "Working with Sourcedesk's {serviceTitle} developers has been an
            incredibly rewarding experience. Their approach is both innovative
            and pragmatic, blending advanced {serviceTitle} techniques with a
            keen understanding of real-world business challenges. What sets them
            apart is their ability to translate complex AI/ML concepts into
            actionable solutions that drive tangible results. They took the time
            to understand our unique requirements and constraints, and their
            iterative development process allowed us to collaborate closely and
            refine the solutions until they were perfectly tailored to our
            needs. The {serviceTitle} models they've built have not only
            enhanced our operational efficiency but have also opened up new
            avenues for growth and competitive differentiation."
          </p>
          <div className="author">
            <p>John Smith</p>
            <p>VP of Data &amp; Analytics</p>
          </div>
          <div className="ratings">
            {Array.from({ length: 5 }, (_, i) => (
              <i
                class="fa fa-star"
                aria-hidden="true"
                style={{ color: "#ffd43b", width: "20px" }}
              ></i>
            ))}
          </div>
        </div>
        <div className="slider-testimonials-slides">
          <p>
            "Sourcedesk's {serviceTitle} Service has been a game-changer for our
            business. Their team of experts helped us implement cutting-edge
            machine-learning models that have significantly improved our
            decision-making processes and operational efficiency. Their deep
            understanding of AI/ML technologies and our industry's nuances
            enabled them to deliver tailored solutions that exceeded our
            expectations."
          </p>
          <div className="author">
            <p>Jane Thomas</p>
            <p>Chief Technology Officer</p>
          </div>
          <div className="ratings">
            {Array.from({ length: 5 }, (_, i) => (
              <i
                class="fa fa-star"
                aria-hidden="true"
                style={{ color: "#ffd43b", width: "20px" }}
              ></i>
            ))}
          </div>
        </div>
      </Slider>
    </div>
  );
};
