import { NavLink } from "react-router-dom";
import LocationMap from "./LocationMap";

import React, { useEffect } from "react";

function Footer() {
 
  return (
    <>
      <footer className="full_width">
        <div className="footer_sub">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4 col-sm-12 col-lg-4 col-xl-4">
                <div className="footer_sub_logo">
                  <NavLink to="/">
                    <img
                      src={window.location.origin + "/images/logo_black.png"}
                      alt="Logo"
                    />
                  </NavLink>
                </div>
                <LocationMap />
              </div>

              <div className="col-12 col-md-8 col-sm-12 col-lg-8 col-xl-8">
                <div className="row quick_links">
                  <div className="col-12 col-md-6 col-sm-6 col-lg-4 col-xl-4">
                    <p className="menu-title">Developer</p>
                    <ul>
                      <li>
                        <NavLink to="/see-all-skills">
                          Hire Developer By Specific Skills
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/hire-our-genius-mind/developers">
                          Hire Development Team
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/schedule-a-call">Book A Call</NavLink>
                      </li>
                    </ul>
                  </div>

                  <div className="col-12 col-md-6 col-sm-6 col-lg-3 col-xl-3">
                    <p className="menu-title">Candidate</p>
                    <ul>
                      <li>
                        <NavLink to="/employee-referral">
                          Employee Referral
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/current-openings">Apply for Jobs</NavLink>
                      </li>
                    </ul>
                  </div>

                  <div className="col-12 col-md-6 col-sm-6 col-lg-2 col-xl-2">
                    <p className="menu-title">Support</p>
                    <ul>
                      <li>
                        <NavLink to="/contact-us">Contact Us</NavLink>
                      </li>
                      <li>
                        <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                      </li>
                      <li>
                        <NavLink to="/terms-service">Terms of Service</NavLink>
                      </li>
                    </ul>
                  </div>

                  <div className="col-12 col-md-6 col-sm-6 col-lg-3 col-xl-3">
                    <p className="menu-title">About Us</p>
                    <ul>
                      <li>
                        <NavLink to="/social-responsibility">
                          Social Responsibility
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/culture-and-values">
                          Culture and Values
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/events">Events</NavLink>
                      </li>
                      <li>
                        <NavLink to="/customer-review">
                          Customer Reviews
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/blog">Blog</NavLink>
                      </li>
                      <li>
                        <NavLink to="/apply-as-talent">Career</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="row">
                  <div className="connect_us">
                    <p className="menu-title">Connect With Us</p>
                    <ul>
                      <li>
                        <NavLink
                          target="_blank"
                          to="https://www.instagram.com/sourcedesk_/"
                        >
                          <img
                            src={
                              window.location.origin + "/images/instagram.svg"
                            }
                            alt="Instagram"
                          />
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          target="_blank"
                          to="https://www.facebook.com/sourcedesk/"
                        >
                          <img
                            // src={window.location.origin + "/images/fb.png"}
                            src={
                              window.location.origin + "/images/facebook.svg"
                            }
                            alt="Facebook"
                          />
                        </NavLink>
                      </li>
                      <li>
                        <NavLink target="_blank" to="https://x.com/sourcedesk_">
                          <img
                            // src={
                            //   window.location.origin +
                            //   "/images/sdgv_twitter_x.png"
                            // }
                            src={
                              window.location.origin + "/images/twitter_x.svg"
                            }
                            alt="Twitter"
                          />
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          target="_blank"
                          to="https://in.linkedin.com/company/sourcedesk-global"
                        >
                          <img
                            // src={window.location.origin + "/images/in.png"}
                            src={
                              window.location.origin + "/images/linkedin.svg"
                            }
                            alt="LinkedIn"
                          />
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          target="_blank"
                          to="https://www.youtube.com/@sourcedesk"
                        >
                          <img
                            // src={window.location.origin + "/images/youtube.png"}
                            src={window.location.origin + "/images/youtube.svg"}
                            alt="YouTube"
                          />
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright">
          <div className="container justify-content-center">
            <p>
              &copy; {new Date().getFullYear()} SourceDesk. All Rights Reserved.
              | Empowering Innovation.
            </p>
          </div>
        </div>
   
      </footer>
    </>
  );
}

export default Footer;
